// grid landscape style
import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const gridMainContainer: SxProps = {
  padding: { md: '20px 25px', sm: '10px 15px' },
  margin: '0 auto',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: { sm: '30px' },
}
export const gridMainBox = (globalTheme: ThemeObjectInterFace): SxProps => ({
  width: '100%',
  padding: '20px 25px',
  border: `2px solid ${globalTheme?.main_color}`,
  margin: '0 auto 20px',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 5.95px 5.95px 2.6px',
})

export const valueContainer: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
}

export const titleMainContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  width: '100%',
  flexDirection: 'column',
}

export const rowContainer = (color: string, isSlider: boolean): SxProps => ({
  flex: '1 1 auto',
  display: 'flex',
  ...(!isSlider && {
    border: `1px solid ${color}`,
    borderBottom: 0,
    '&:last-child': {
      borderBottom: `1px solid ${color}`,
    },
  }),
})

export const ratingPointText = (globalTheme: ThemeObjectInterFace): SxProps => {
  return {
    color: globalTheme?.main_color,
    fontWeight: '300',
    textAlign: 'end',
    fontSize: '20px',
    padding: '10px 20px',
  }
}

/* istanbul ignore next 2 */
export const textContainer = (height: number): SxProps => ({
  height: height > 0 ? height : 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

export const textWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '20%',
}

export const leftContainer = (isSliderType: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minWidth: isSliderType ? '60%' : null,
})

/* istanbul ignore next 2 */
export const sliderContainer = (height: number) => ({
  height: height > 0 ? height : 'inherit',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

/* istanbul ignore next 8 */
export const gridValueContent = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean,
  disabled: boolean,
  height: number,
): SxProps => {
  return {
    height: height > 0 ? height : 'inherit',
    flex: '1 1 auto',
    padding: '10px 20px',
    borderRight: `1px solid ${globalTheme.main_color}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    color: globalTheme?.main_color,
    cursor: 'pointer',
    textAlign: 'center',
    '&:last-child': {
      borderRight: 0,
    },
    ...(disabled && {
      backgroundColor: '#ddd',
      color: 'gray',
    }),
    ...(isSelected && {
      backgroundColor: globalTheme?.main_color,
      color: 'white',
    }),
  }
}

export const gridButtonText = (isSelected: boolean): SxProps => ({
  fontSize: '20px',
  fontWeight: '400',
  marginBottom: '0',
  ...(isSelected && {
    color: '#fff',
  }),
})
