import { Slider } from '@mui/material'
import { Box } from '@mui/system'
import { forwardRef } from 'react'
import { VideoDialResponeProps } from '../../../util/types'
import { videoIframe } from '../VideoResponse/styles'
import {
  dialContainer,
  dialSliderStyle,
  dialWraper,
  hintsStyle,
  iframeStyle,
  mainBoax,
  mobileViewWrapper,
  sliderWrapper,
  videoWrapper,
} from './style'
import { Hints } from '../../ui'
import { handleKeyDown, handleChange } from '../VideoResponse/videoResponseFunc'

const VideoDialResponse = forwardRef<
  HTMLIFrameElement | HTMLDivElement,
  VideoDialResponeProps
>(
  (
    {
      message,
      isLandScapeMode,
      dialOpen,
      sliderValue,
      mobileView,
      setSliderValue,
      offsetSeconds,
    },
    devRef,
  ) => {
    const sliderMarks = message?.options?.map((item) => {
      return { value: item.value, label: item.text }
    })
    const lastIndexOfSliderLabel = message?.options?.length
    /* istanbul ignore next */
    const hintText = message?.playing_message ?? ''
    return (
      <Box ref={devRef}>
        {
          /* istanbul ignore next */
          isLandScapeMode && message?.options ? (
            <Box sx={{ ...dialContainer(mobileView, dialOpen) }}>
              <Box sx={{ ...dialWraper(dialOpen) }}>
                {!mobileView && dialOpen && (
                  <Hints text={hintText} sx={hintsStyle(mobileView)} />
                )}
                <Box sx={{ ...mainBoax(mobileView, dialOpen) }}>
                  <Box sx={{ ...videoWrapper(mobileView, dialOpen) }}>
                    <iframe
                      src={message?.video}
                      title="Video Player"
                      allow="encrypted-media"
                      style={iframeStyle(dialOpen, isLandScapeMode)}
                      allowFullScreen={false}
                    ></iframe>
                  </Box>
                  {dialOpen && (
                    <Box sx={mobileViewWrapper(mobileView)}>
                      {mobileView && dialOpen && (
                        <Hints text={hintText} sx={hintsStyle(mobileView)} />
                      )}
                      <Box sx={sliderWrapper(mobileView)}>
                        <Slider
                          data-testid={'slidr'}
                          size="small"
                          valueLabelDisplay="on"
                          orientation={mobileView ? 'vertical' : 'horizontal'}
                          aria-label="slider-with-focus"
                          marks={sliderMarks}
                          sx={dialSliderStyle(
                            mobileView,
                            lastIndexOfSliderLabel,
                          )}
                          value={sliderValue}
                          onKeyDown={(
                            event: React.KeyboardEvent<HTMLDivElement>,
                          ) => {
                            handleKeyDown(
                              event,
                              setSliderValue,
                              sliderValue,
                              offsetSeconds,
                              message.post_data ?? '',
                            )
                          }}
                          onChange={(event, value) => {
                            /* istanbul ignore next */
                            handleChange(
                              value as number,
                              setSliderValue,
                              message?.post_data ?? '',
                              offsetSeconds,
                            )
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <iframe
              data-testid="video-container"
              title="VideoPlayer"
              allow="encrypted-media"
              src={message?.video}
              style={videoIframe(!!isLandScapeMode)}
              allowFullScreen={true}
            ></iframe>
          )
        }
      </Box>
    )
  },
)

export default VideoDialResponse
