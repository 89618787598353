import { CSSProperties } from 'react'
import { videoIframe } from '../VideoResponse/styles'

export const dialContainer = (mobileView: boolean, dialOpen: boolean) =>
  dialOpen && {
    position: 'fixed',
    inset: 0,
    overflow: 'auto',
    backgroundColor: 'black',
    paddingX: mobileView ? 1 : 4,
    paddingTop: mobileView ? '1.5%' : '1%',
  }

export const dialWraper = (dialOpen: boolean) =>
  dialOpen && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }

export const mainBoax = (mobileView: boolean, dialOpen: boolean) =>
  dialOpen && {
    position: 'relative',
    display: 'flex',
    flexDirection: mobileView ? 'row' : 'column',
    width: '100%',
    height: '100%',
  }

export const videoWrapper = (mobileView: boolean, dialOpen: boolean) =>
  dialOpen && {
    width: mobileView ? '70%' : '100%',
    position: 'relative',
    paddingBottom: mobileView ? '42%' : '35%',
    inset: 0,
  }

export const hintsStyle = (mobileView: boolean) =>
  mobileView
    ? {
        color: '#49AE8A',
        fontSize: 14,
        textAlign: 'center',
        paddingX: 2,
        marginTop: 2,
      }
    : { color: '#49AE8A', fontSize: 18 }

export const iframeStyle = (
  dialOpen: boolean,
  isLandScapeMode: boolean,
): CSSProperties =>
  /* istanbul ignore next */
  dialOpen
    ? {
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        border: 'none',
      }
    : videoIframe(!!isLandScapeMode)

export const mobileViewWrapper = (mobileView: boolean) => ({
  width: mobileView ? '30%' : '100%',
  overflow: mobileView ? 'auto' : 'visible',
})

export const sliderWrapper = (mobileView: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: mobileView ? 'column' : 'row',
  height: mobileView ? '65%' : '80%',
  paddingX: 4,
})

export const dialSliderStyle = (
  mobileView: boolean,
  lastIndexOfSliderLabel: number | undefined,
) => ({
  ...(mobileView && { marginLeft: 6 }),
  boxShadow: 'none',
  marginTop: mobileView ? 4 : 10,
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#44A280',
    color: '#44A280',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-markLabel': mobileView
    ? {
        position: 'absolute',
        left: '-255%',
        color: '#44A280',
        textAlign: 'center',
        width: '90px',
        wordBreak: 'break-all',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        transform: 'translateY(17%)',
      }
    : {
        color: '#49AE8A',
        textAlign: 'start',
        ' &[data-index="0"]': {
          marginLeft: 2.5,
        },
        [`&[data-index="${lastIndexOfSliderLabel}"]`]: {
          marginRight: 3,
        },
      },
  '& .MuiSlider-mark': {
    backgroundColor: '#49AE8A',
    height: '10px',
    width: '10px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    background: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    paddingLeft: 0.8,
    ...(mobileView && {
      transform: 'translateX(250%)',
    }),
    ...(mobileView && {
      marginLeft: 1,
      rotate: '90deg',
    }),

    border: 'none',
  },

  '.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover ': {
    boxShadow: 'none !important',
  },
})
